export const categoryFeatures = [
  {
    title: 'Healthcare Provider Email Broadcast Services',
    code: 'HPEBS',
    color: 'yellow',
    features: [
      { title: 'Email Broadcast Services', code: 'EBS' }
    ]
  },
  {
    title: 'Healthcare Provider Mailing Lists',
    code: 'HPML',
    color: 'green lighten-3',
    features: [
      { title: 'Download Mailing Lists Immediately', code: 'DL' }
    ]
  },
  {
    title: 'Identify Target Audience',
    code: 'ITA',
    color: 'deep-orange lighten-3',
    features: [
      { title: 'Demographic Selection Criteria', code: 'DSC' },
      { title: 'Email Suppression', code: 'ES' },
      { title: 'Global Email Suppression', code: 'GES' },
      { title: 'Target File Matching – NPI # ME # Name and Address', code: 'TFM' },
      { title: 'Physician Look-up', code: 'PL' },
      { title: 'Physician – Geo Omit', code: 'PGO' },
      { title: 'Physician Look-up Suppression File', code: 'PLS' },
      { title: 'Email Behaviors Target Received, Opens, Click Thru’s, Previous Broadcasts', code: 'EB' }
      // { title: 'Classic Reporting', code: 'CLR' }
    ]
  },
  {
    title: 'Broadcast Services',
    code: 'BS',
    color: 'purple lighten-3',
    features: [
      { title: 'A/B Subject Line Testing', code: 'AB' },
      { title: 'Re-Send Programs', code: 'RP' },
      { title: 'Content Editor', code: 'CE' },
      { title: 'Spam Score Preview', code: 'SSP' },
      { title: 'Test Broadcasts', code: 'TB' },
      { title: 'Schedule and Perform Broadcasts', code: 'SPB' },
      { title: 'Saved Count and Order History – 2 years', code: 'SC' },
      { title: 'On-line Onboarding Session', code: 'OOS' },
      { title: 'On Site One Day Training Session', code: 'ODTS' },
      { title: 'ME Number Release', code: 'MENR' },
      { title: 'Text Analysis', code: 'TA' }
    ]
  },
  {
    title: 'Reporting',
    code: 'R',
    color: 'blue lighten-1',
    features: [
      { title: 'Standard Engagement Reports', code: 'SER' },
      { title: 'Lead Scored Prospects and Phone Follow-up', code: 'LSP' },
      { title: 'Comprehensive Enhanced Engagement', code: 'EER' },
      { title: 'Broadcast Intelligence Reporting', code: 'BIR' },
      { title: 'Broadcast Intelligence Heatmap', code: 'BIH' },
      { title: 'KPI Widgets', code: 'KPIW' },
      { title: 'Link Data', code: 'LD' },
      { title: 'Age Data', code: 'AD' },
      { title: 'Direct Mail Analytics Report', code: 'DMAR' },
      { title: 'Email Analytics Report', code: 'EAR' },
      { title: 'Service Line/Specialty Report', code: 'SLSR' },
      { title: 'Service Line/Offer Type Report', code: 'SLOTR' },
      { title: 'Specialty Engagement Report', code: 'SPER' },
      { title: 'Top/Bottom Performers Report', code: 'TBPR' },
      { title: 'Best Day Report', code: 'BDR' },
      { title: 'Engagement Comparison Report', code: 'ECR' }
    ]
  },
  {
    title: 'Administrative',
    code: 'AD',
    color: 'pink lighten-3',
    features: [
      { title: 'Billing History', code: 'BH' },
      { title: 'User Management', code: 'UM' },
      { title: 'User Viewing', code: 'UV' },
      { title: 'Account Summary', code: 'AS' }
    ]
  },
  {
    title: 'Content Library',
    code: 'CL',
    color: 'cyan lighten-3',
    features: [
      { title: 'Content Library Management', code: 'CLM' },
      { title: 'Data Feed', code: 'DF' }
    ]
  },
  {
    title: 'MMS - Special Admin',
    code: 'MMS',
    color: 'orange lighten-3',
    features: [
      { title: 'Tenant Management', code: 'TM' },
      { title: 'Cross Tenant EBT', code: 'TEBT' },
      { title: 'Cross Tenant Counts', code: 'TC' },
      { title: 'Cross Tenant Reporting and Analytics', code: 'TRA' },
      { title: 'Email Release', code: 'ER' }
    ]
  }
]
