<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5 font-italic">Users</span>
        <v-spacer />
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          :show-non-contracted-tenants="false"
        />
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
        <v-spacer />
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              color="success"
              dark
              class="mb-2"
              v-on="onTooltip"
              @click="getUsersReport()"
            >
              <v-icon color="white">
                mdi-file-download
              </v-icon>
            </v-btn>
          </template>
          <span>Download Users Report CSV</span>
        </v-tooltip>
        <v-spacer />
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
          persistent
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="userHasRWDClaim()"
              color="success"
              dark
              class="mb-2"
              v-on="on"
            >
              <v-icon left>
                mdi-account-plus
              </v-icon>
              New User
            </v-btn>
          </template>
          <v-card>
            <v-card-title
              class="success white--text"
              primary-title
            >
              <span class="text-h5 font-italic">{{ formTitle }}</span>
            </v-card-title>
            <v-form ref="form">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editedUser.firstName"
                        label="First Name"
                        :error-messages="firstNameErrors"
                        required
                        @input="$v.editedUser.firstName.$touch()"
                        @blur="$v.editedUser.firstName.$touch()"
                        @keyup.enter="save"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="editedUser.lastName"
                        label="Last Name"
                        :error-messages="lastNameErrors"
                        required
                        @input="$v.editedUser.lastName.$touch()"
                        @blur="$v.editedUser.lastName.$touch()"
                        @keyup.enter="save"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-text-field
                        v-model="editedUser.email"
                        label="Email"
                        :error-messages="emailErrors"
                        required
                        type="email"
                        @input="$v.editedUser.email.$touch()"
                        @blur="$v.editedUser.email.$touch()"
                        @keyup.enter="save"
                      />
                    </v-col>
                    <v-col
                      v-if="showViewAsCustomer"
                      cols="12"
                      sm="12"
                    >
                      <v-select
                        v-model="editedUser.tenantId"
                        hide-details
                        :items="contractedTenants"
                        :disabled="!this.isCreatingNewUser"
                        :error-messages="tenantIdErrors"
                        label="Account"
                        required
                        @input="$v.editedUser.tenantId.$touch()"
                        @blur="$v.editedUser.tenantId.$touch()"
                        @keyup.enter="save"
                      >
                        <template v-slot:item="{ parent, item, on, attrs }">
                          <v-list-item-content
                            v-if="item.text || item.nowwId"
                            class="py-2"
                          >
                            <v-list-item-title v-text="item.text" />
                            <v-list-item-subtitle v-text="item.nowwId" />
                          </v-list-item-content>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="editedUser.inscriptioRoleName"
                        :items="inscriptioRoleSelect"
                        item-text="role"
                        item-value="roleEnum"
                        label="Role"
                        :error-messages="inscriptioRoleNameErrors"
                        required
                        @input="$v.editedUser.inscriptioRoleName.$touch()"
                        @blur="$v.editedUser.inscriptioRoleName.$touch()"
                        @keyup.enter="save"
                      />
                    </v-col>
                    <v-col
                      v-if="editedUser.tenantId === mmsTenantId"
                      cols="12"
                      sm="6"
                    >
                      <v-checkbox
                        v-model="editedUser.mmsCustomerRestrictedByOwner"
                        color="primary"
                        label="MMS Sales"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="tertiary"
                  :disabled="isLoading()"
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  :loading="isLoading()"
                  @click="save"
                >
                  {{ formSubmit }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-row
        v-show="isMMSAdmin"
        align="end"
        no-gutters
      >
        <v-col class="d-flex justify-end mr-10">
          <v-switch
            v-model="usersLocked"
            :loading="lockLoading"
            :prepend-icon="usersLocked === false ? 'mdi-lock-open' : 'mdi-lock'"
            :label="usersLocked === false ? 'Unlocked Users' : 'Locked Users'"
            @change="lockUnlockUsers()"
          />
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-card-text>
          <v-skeleton-loader
            v-show="isLoading()"
            type="table"
          />
          <v-data-table
            v-show="!isLoading()"
            id="usersTable"
            :headers="computedHeaders"
            :items="users"
            :search="search"
            :custom-sort="usersSort"
            :sort-by="['inscriptioRoleName']"
            :expanded="expandedUsers"
            item-key="userId"
            sort-desc
            @click:row="toggleExpandRow"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="computedHeaders.length"
                class="px-0"
              >
                <UserHistory :user-id="item.userId" />
              </td>
            </template>
            <template v-slot:item.mmsCustomerRestrictedByOwner="{ item }">
              <v-simple-checkbox
                v-if="item.mmsCustomerRestrictedByOwner !== null"
                v-model="item.mmsCustomerRestrictedByOwner"
                disabled
                color="primary"
              />
            </template>
            <template v-slot:item.inscriptioRoleName="{ item }">
              <v-chip
                v-if="getRoleItem(item.roleName)['color']"
                :color="getRoleItem(item.roleName)['color']"
                dark
              >
                {{ item.inscriptioRole }}
              </v-chip>
            </template>
            <template v-slot:item.lastSignInDays="{ item }">
              <v-tooltip
                v-if="item.lastSignInDays >= item.configSignInExpirationDays"
                left
              >
                <template v-slot:activator="{ on }">
                  <v-chip
                    color="error"
                    dark
                    v-on="on"
                  >
                    {{ item.lastSignInDays }}
                  </v-chip>
                </template>
                <span>User expired {{ item.lastSignInDays - item.configSignInExpirationDays }} days ago</span>
              </v-tooltip>
              <div v-else>
                {{ item.lastSignInDays }}
              </div>
            </template>

            <template v-slot:item.lastScheduledMailingDays="{ item }">
              {{ item.tenantNowwId === 1 ? '' : item.lastScheduledMailingDays || 'No Mailings' }}
            </template>
            <template v-slot:item.actions="{ item }">
              <span v-if="showUserActions(item)">
                <v-icon
                  small
                  class="mr-2"
                  color="success"
                  @click="editUser(item)"
                  @click.native.stop
                >
                  mdi-pencil
                </v-icon>
                <v-menu
                  v-if="showUserDelete(item)"
                  right
                  x-offset
                  origin="right"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="error"
                      class="mr-2"
                      v-on="on"
                      @click.native.stop
                    >
                      mdi-delete
                    </v-icon>
                  </template>

                  <v-card>
                    <v-card-text class="pa-5">
                      <span>Are you sure you want to delete this user?</span>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="tertiary"
                        :disabled="isLoading()"
                        @click="closeDelete"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="error"
                        :loading="isLoading()"
                        @click="deleteUser(item)"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </span>
              <v-tooltip top>
                <template v-slot:activator="{ on: onTooltip }">
                  <v-icon
                    style="cursor: pointer;"
                    class="mr-2"
                    v-on="{ ...onTooltip }"
                  >
                    mdi-chevron-{{ item._expandedIcon }}
                  </v-icon>
                </template>
                <span>See details</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import mutationTypes, {
  SET_LOADING_MESSAGE,
  SET_GLOBAL_SNACKBAR
} from '@/store/mutation-types'

import { validationMixin } from 'vuelidate'
import { downloadFileMixin } from '@/shared/mixins/general'
import { required, email } from 'vuelidate/lib/validators'
import { mapMutations, mapGetters } from 'vuex'
import { tenantService, userService } from '@/shared/services'
import { roles, packageRoles } from '@/shared/models/auth'
import claims from '@/shared/models/auth/claims'

export default {
  name: 'ManageUsers',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer'),
    UserHistory: () =>
      import('@/views/pages/components/user-state/UserHistory')
  },

  mixins: [validationMixin, downloadFileMixin],

  validations: {
    editedUser: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      inscriptioRoleName: { required },
      tenantId: { required }
    }
  },

  data: () => ({
    search: '',
    lock: false,
    usersLocked: false,
    lockLoading: false,
    dialog: false,
    deleteDialog: false,
    loadingDownload: false,
    reportData: [],
    editedIndex: -1,
    mmsTenantId: process.env.VUE_APP_MMS_TENANT_ID,
    editedUser: {
      search: null,
      userId: '',
      tenantId: '',
      firstName: '',
      lastName: '',
      email: '',
      roleName: '',
      noFilter: true,
      packageEnum: null,
      mmsCustomerRestrictedByOwner: false,
      inscriptioRoleName: 0
    },
    defaultUser: {
      search: null,
      userId: '',
      tenantId: '',
      firstName: '',
      lastName: '',
      email: '',
      roleName: '',
      noFilter: true,
      packageEnum: null,
      mmsCustomerRestrictedByOwner: false,
      inscriptioRoleName: 0
    },
    headers: [
      { text: 'User Id', value: 'userId', hidden: true },
      { text: 'Tenant Id', value: 'tenantId', hidden: true },
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'inscriptioRoleName' },
      { text: 'Password Expiration (Days)', value: 'passwordExpiresInDays' },
      { text: 'Last Log In (Days)', value: 'lastSignInDays' },
      {
        text: 'Config Sign In Expiration (Days)',
        value: 'configSignInExpirationDays',
        hidden: true
      }
    ],
    actionHeaders: [
      { text: 'Actions', value: 'actions', sortable: false, filterable: false }
    ],
    users: [],
    contractedTenants: [],
    expandedUsers: []
  }),

  created () {
    this.loadUsers()
    this.defaultUser.tenantId = this.editedUser.tenantId = this.getTenantId()
    this.defaultUser.packageEnum = this.editedUser.packageEnum = packageRoles.find(
      x => x.value === this.$store.getters['auth/getRole']()
    ).packageOrder
    if (this.showViewAsCustomer) this.loadContractedCustomers()
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [mutationTypes.SET_VIEW_AS_CUSTOMER]
      if (listeners.includes(mutation.type)) {
        this.loadUsers()
        // TODO: think this idea below could apply to view as customer change? QOL change
        // this.defaultUser.tenantId = this.editedUser.tenantId = this.getTenantId()
        // this.defaultUser.packageEnum = this.editedUser.packageEnum = packageRoles.find(
        //   x => x.value === this.$store.getters['auth/getRole']()
        // ).packageOrder
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  computed: {
    ...mapGetters(['isLoading', 'viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getClaims',
      'getRole',
      'getRolePackageCode',
      'getRoleTitle',
      'getTenantId',
      'getTenantCrmId',
      'hasClaimKV'
    ]),
    computedHeaders () {
      var preHeaders = []
      var postHeaders = []
      if (this.$store.getters['isViewAllCustomers']()) {
        preHeaders.push({ text: 'Account Name', value: 'tenantName' })
      }
      if (!this.$store.getters['isViewAsMMS']()) {
        postHeaders.push({ text: 'Last Mailing Scheduled In (Days)', value: 'lastScheduledMailingDays' })
      }
      if (this.users && this.users.some(x => x.mmsCustomerRestrictedByOwner !== null)) {
        postHeaders.push({ text: 'MMS Sales', value: 'mmsCustomerRestrictedByOwner' })
      }
      return [
        ...preHeaders,
        ...this.headers,
        ...postHeaders,
        ...this.actionHeaders
      ].filter(x => !x.hidden)
    },
    // filteredContractedTenants() {
    //   return this.contractedTenants.filter(this.filterFunc);
    // },
    isCreatingNewUser () {
      return this.editedIndex === -1
    },
    formTitle () {
      return this.isCreatingNewUser ? 'New User' : 'Edit User'
    },
    formSubmit () {
      return this.isCreatingNewUser ? 'Create' : 'Save'
    },
    firstNameErrors () {
      const errors = []
      if (!this.$v.editedUser.firstName.$dirty) return errors
      !this.$v.editedUser.firstName.required &&
        errors.push('First Name is required')
      return errors
    },
    lastNameErrors () {
      const errors = []
      if (!this.$v.editedUser.lastName.$dirty) return errors
      !this.$v.editedUser.lastName.required &&
        errors.push('Last Name is required')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.editedUser.email.$dirty) return errors
      !this.$v.editedUser.email.email && errors.push('Must be a valid email')
      !this.$v.editedUser.email.required && errors.push('Email is required')
      return errors
    },
    inscriptioRoleNameErrors () {
      const errors = []
      if (!this.$v.editedUser.inscriptioRoleName.$dirty) return errors
      !this.$v.editedUser.inscriptioRoleName.required && errors.push('Role is required')
      return errors
    },
    tenantIdErrors () {
      const errors = []
      if (!this.$v.editedUser.tenantId.$dirty) return errors
      !this.$v.editedUser.tenantId.required &&
        errors.push('Account is required')
      return errors
    },
    inscriptioRoleSelect () {
      if (
        this.editedUser.packageEnum <
        packageRoles.find(
          x => x.value === this.$store.getters['auth/getRole']()
        ).packageEnum
      ) {
        return packageRoles.filter(
          x => x.packageEnum === this.editedUser.packageEnum
        )
      } else {
        return packageRoles.filter(
          x =>
            x.packageEnum === this.editedUser.packageEnum &&
            this.compareRole(this.getRole(), x.value) >= 0
        )
      }
    },
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isMMSAdmin () {
      return this.hasClaimKV(claims.MMS_TM)
    }
  },

  methods: {
    ...mapMutations(['setGlobalSnackbar', 'setLoadingMessage']),
    loadUsers () {
      if (this.showViewAsCustomer) {
        if (
          !this.viewAsCustomer() ||
          this.$store.getters['isViewAllCustomers']()
        ) {
          tenantService.getAllUsers().then(resp => {
            if (resp.errorResponse) {
              this.$store.commit('setGlobalSnackbar', {
                message: resp.errorResponse,
                color: 'error'
              })
              this.users = []
            } else {
              this.users = resp.map(x => (
                {
                  ...x,
                  _expandedIcon: 'down'
                }
              ))
              this.getLockUnlockResponse()
            }
          })
        } else {
          tenantService
            .getUsersByTenantCrm(this.viewAsCustomer())
            .then(resp => {
              if (resp.errorResponse) {
                this.$store.commit('setGlobalSnackbar', {
                  message: resp.errorResponse,
                  color: 'error'
                })
                this.users = []
              } else {
                this.users = resp.map(x => (
                  {
                    ...x,
                    _expandedIcon: 'down'
                  }
                ))
                this.getLockUnlockResponse()
              }
            })
        }
      } else {
        tenantService.getUsersByTenantCrm(this.getTenantCrmId()).then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.users = []
          } else {
            this.users = resp.map(x => (
              {
                ...x,
                _expandedIcon: 'down'
              }
            ))
            this.getLockUnlockResponse()
          }
        })
      }
    },
    loadContractedCustomers () {
      tenantService.getTenants().then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
          this.contractedTenants = []
        } else {
          this.contractedTenants = resp.map(function (val, index) {
            return {
              text: val.name,
              value: val.id,
              nowwId: val.nowwId,
              crmId: val.crmId,
              enum: val.package
            }
          })
        }
      })
    },
    getRoleItem (roleName) {
      if (!roleName) return null
      return packageRoles.find(x => x.text === roleName)
    },
    userHasRWDClaim () {
      return this.$store.getters['auth/hasClaimKV'](claims.AD_UM)
    },
    showUserActions (userItem) {
      // check claims for edit/add/delete
      if (!this.userHasRWDClaim()) {
        return false
      }
      // compare current user's tenant to the the view as customer
      if (this.$store.getters['isViewAllCustomers']()) {
        if (userItem.tenantCrmId === this.getTenantCrmId()) {
          return this.compareRole(this.getRole(), userItem.roleName) >= 0
        } else {
          return true
        }
      } else if (
        this.viewAsCustomer() &&
        this.viewAsCustomer() !== this.getTenantCrmId()
      ) { return true } else return this.compareRole(this.getRole(), userItem.roleName) >= 0
    },
    showUserDelete (userItem) {
      if (userItem.email === this.$store.getters['auth/getUser']().email) {
        return false
      }
      return this.showUserActions(userItem)
    },
    compareRole (role1, role2) {
      let a = this.getRoleItem(role1)
      let b = this.getRoleItem(role2)
      return a.roleOrder === b.roleOrder
        ? a.packageOrder === b.packageOrder
          ? 0
          : a.packageOrder > b.packageOrder
            ? 1
            : -1
        : a.roleOrder > b.roleOrder
          ? 1
          : -1
    },
    usersSort (items, index, isDescending) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
        return items
      }
      items.sort((a, b) => {
        if (index[0] === 'inscriptioRoleName') {
          if (!isDescending[0]) {
            return this.compareRole(a.roleName, b.roleName)
          } else {
            return this.compareRole(b.roleName, a.roleName)
          }
        } else {
          if (!isDescending[0]) {
            return a[index[0]] > b[index[0]] ? 1 : -1
          } else {
            return b[index[0]] > a[index[0]] ? 1 : -1
          }
        }
      })
      return items
    },
    // filterFunc(x) {
    //   return (
    //     !this.editedUser.search ||
    //     x.text.toLowerCase().includes(this.editedUser.search.toLowerCase()) ||
    //     x.nowwId.toString().includes(this.editedUser.search)
    //   );
    // },
    editUser (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedUser = Object.assign({}, item)
      this.dialog = true
    },
    async deleteUser (item) {
      let resp = await tenantService.deleteUser(item.userId)
      if (resp.errorResponse) {
        this.setGlobalSnackbar({
          message: `${item.email} could not be deleted`,
          color: 'error'
        })
      } else {
        this.setGlobalSnackbar({
          message: `${item.email} has been deleted`,
          color: 'success'
        })
        const index = this.users.indexOf(item)
        this.users.splice(index, 1)
      }
      this.closeDelete()
    },
    getLockUnlockResponse () {
      this.users.length === 0 ? this.usersLocked = true : this.usersLocked = false
    },
    async lockUnlockUsers () {
      var _this = this
      this.lockLoading = true
      var lockedUsers = []
      _this.getLockUnlockResponse()
      var usersId = _this.users.map(a => a.userId)
      if (_this.users.length > 0 && !_this.usersLocked) {
        let resp = await tenantService.lockUnlockUsers({
          userIds: usersId,
          lockUsers: true })
        if (resp.errorResponse) {
          _this.setGlobalSnackbar({
            message: `Users could not be locked`,
            color: 'error'
          })
        } else {
          _this.lockLoading = false
          _this.usersLocked = true
          _this.loadUsers()
          _this.setGlobalSnackbar({
            message: `All users have been locked`,
            color: 'success'
          })
        }
      } else {
        let resp = await tenantService.getUsersLockedByTenantCrm(_this.viewAsCustomer())
          .then(resp => {
            lockedUsers = resp.map(a => a.userId)
          })
        let response = await tenantService.lockUnlockUsers({
          userIds: lockedUsers,
          lockUsers: false
        })
        if (response.errorResponse) {
          _this.setGlobalSnackbar({
            message: `Users could not be unlocked`,
            color: 'error'
          })
        } else {
          _this.lockLoading = false
          _this.usersLocked = false
          _this.loadUsers()
          _this.setGlobalSnackbar({
            message: `All users have been unlocked`,
            color: 'success'
          })
        }
      }
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedUser = Object.assign({}, this.defaultUser)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      }, 300)
    },
    closeDelete () {
      this.deleteDialog = false
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (!this.isCreatingNewUser) {
        let resp = await tenantService.updateUser(this.editedUser)
        if (resp.errorResponse) {
          this.setGlobalSnackbar({
            message: `${this.editedUser.email} could not be updated`,
            color: 'error'
          })
        } else {
          this.setGlobalSnackbar({
            message: `${this.editedUser.email} has been updated`,
            color: 'success'
          })
          Object.assign(this.users[this.editedIndex], this.editedUser)
          this.loadUsers()
        }
      } else {
        let resp = await tenantService.createUser(this.editedUser)
        if (resp.errorResponse) {
          this.setGlobalSnackbar({
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          this.editedUser.userId = resp
          // this.editedUser.passwordExpiresInDays = 90;
          // this.editedUser.lastSignInDays = 0;
          this.setGlobalSnackbar({
            message: `${this.editedUser.email} has been created`,
            color: 'success'
          })
          // TODO: fix bug so editedUser gets added to list based on tenantId (not viewAsCustomer aka crmId)
          // if (this.$store.getters['isViewAllCustomers']() || this.editedUser.tenantId === this.viewAsCustomer()) {
          //   this.users.push(this.editedUser)
          // }
          this.loadUsers()
        }
      }
      this.close()
    },
    async getUsersReport () {
      this.loadingDownload = true
      var tenantCrmId = this.showViewAsCustomer ? this.viewAsCustomer() : this.getTenantCrmId()
      await userService.usersReport(tenantCrmId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.$_downloadFileMixin_downloadResponse(resp)
          }
        })
        .finally(() => {
          this.loadingDownload = false
        })
    },
    toggleExpandRow (value) {
      if (this.expandedUsers.includes(value)) {
        value._expandedIcon = 'down'
        this.expandedUsers.splice(this.expandedUsers.indexOf(value), 1)
      } else {
        value._expandedIcon = 'up'
        this.expandedUsers.push(value)
      }
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    deleteDialog (val) {
      val || this.closeDelete()
    },
    'editedUser.tenantId': {
      handler (newVal) {
        // this.editedUser.search = null;
        if (!this.showViewAsCustomer) return
        if (!newVal) return
        let _tenant = this.contractedTenants.find(x => x.value === newVal)
        if (_tenant) {
          this.defaultUser.tenantId = newVal
          this.defaultUser.packageEnum = this.editedUser.packageEnum = _tenant.enum
        }
      }
    },
    users (newValue, oldValue) {
      this.expandedUsers = []
    },
    lock (newValue) {
      this.lockUsers()
    }
  }
}
</script>
