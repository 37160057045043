var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "text-h5 font-italic" }, [
                _vm._v("Users")
              ]),
              _c("v-spacer"),
              _vm.showViewAsCustomer
                ? _c("ViewAsCustomer", {
                    attrs: { "show-non-contracted-tenants": false }
                  })
                : _vm._e(),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              }),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var onTooltip = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mb-2",
                                attrs: { color: "success", dark: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.getUsersReport()
                                  }
                                }
                              },
                              onTooltip
                            ),
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(
                                  "\n              mdi-file-download\n            "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Download Users Report CSV")])]
              ),
              _c("v-spacer"),
              _c("v-spacer"),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500px", persistent: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _vm.userHasRWDClaim()
                            ? _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mb-2",
                                    attrs: { color: "success", dark: "" }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(
                                      "\n              mdi-account-plus\n            "
                                    )
                                  ]),
                                  _vm._v("\n            New User\n          ")
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "success white--text",
                          attrs: { "primary-title": "" }
                        },
                        [
                          _c("span", { staticClass: "text-h5 font-italic" }, [
                            _vm._v(_vm._s(_vm.formTitle))
                          ])
                        ]
                      ),
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "First Name",
                                              "error-messages":
                                                _vm.firstNameErrors,
                                              required: ""
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.$v.editedUser.firstName.$touch()
                                              },
                                              blur: function($event) {
                                                return _vm.$v.editedUser.firstName.$touch()
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.save.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.editedUser.firstName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedUser,
                                                  "firstName",
                                                  $$v
                                                )
                                              },
                                              expression: "editedUser.firstName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Last Name",
                                              "error-messages":
                                                _vm.lastNameErrors,
                                              required: ""
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.$v.editedUser.lastName.$touch()
                                              },
                                              blur: function($event) {
                                                return _vm.$v.editedUser.lastName.$touch()
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.save.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.editedUser.lastName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedUser,
                                                  "lastName",
                                                  $$v
                                                )
                                              },
                                              expression: "editedUser.lastName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Email",
                                              "error-messages": _vm.emailErrors,
                                              required: "",
                                              type: "email"
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.$v.editedUser.email.$touch()
                                              },
                                              blur: function($event) {
                                                return _vm.$v.editedUser.email.$touch()
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.save.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.editedUser.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedUser,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "editedUser.email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.showViewAsCustomer
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  "hide-details": "",
                                                  items: _vm.contractedTenants,
                                                  disabled: !this
                                                    .isCreatingNewUser,
                                                  "error-messages":
                                                    _vm.tenantIdErrors,
                                                  label: "Account",
                                                  required: ""
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.$v.editedUser.tenantId.$touch()
                                                  },
                                                  blur: function($event) {
                                                    return _vm.$v.editedUser.tenantId.$touch()
                                                  },
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.save.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function(ref) {
                                                        var parent = ref.parent
                                                        var item = ref.item
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          item.text ||
                                                          item.nowwId
                                                            ? _c(
                                                                "v-list-item-content",
                                                                {
                                                                  staticClass:
                                                                    "py-2"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      domProps: {
                                                                        textContent: _vm._s(
                                                                          item.text
                                                                        )
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-subtitle",
                                                                    {
                                                                      domProps: {
                                                                        textContent: _vm._s(
                                                                          item.nowwId
                                                                        )
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1225242470
                                                ),
                                                model: {
                                                  value:
                                                    _vm.editedUser.tenantId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedUser,
                                                      "tenantId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedUser.tenantId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", sm: "6" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.inscriptioRoleSelect,
                                              "item-text": "role",
                                              "item-value": "roleEnum",
                                              label: "Role",
                                              "error-messages":
                                                _vm.inscriptioRoleNameErrors,
                                              required: ""
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.$v.editedUser.inscriptioRoleName.$touch()
                                              },
                                              blur: function($event) {
                                                return _vm.$v.editedUser.inscriptioRoleName.$touch()
                                              },
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.save.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.editedUser
                                                  .inscriptioRoleName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editedUser,
                                                  "inscriptioRoleName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedUser.inscriptioRoleName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.editedUser.tenantId ===
                                      _vm.mmsTenantId
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12", sm: "6" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  color: "primary",
                                                  label: "MMS Sales"
                                                },
                                                model: {
                                                  value:
                                                    _vm.editedUser
                                                      .mmsCustomerRestrictedByOwner,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.editedUser,
                                                      "mmsCustomerRestrictedByOwner",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedUser.mmsCustomerRestrictedByOwner"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "tertiary",
                                    disabled: _vm.isLoading()
                                  },
                                  on: { click: _vm.close }
                                },
                                [
                                  _vm._v(
                                    "\n                Cancel\n              "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "success",
                                    loading: _vm.isLoading()
                                  },
                                  on: { click: _vm.save }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.formSubmit) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMMSAdmin,
                  expression: "isMMSAdmin"
                }
              ],
              attrs: { align: "end", "no-gutters": "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "d-flex justify-end mr-10" },
                [
                  _c("v-switch", {
                    attrs: {
                      loading: _vm.lockLoading,
                      "prepend-icon":
                        _vm.usersLocked === false
                          ? "mdi-lock-open"
                          : "mdi-lock",
                      label:
                        _vm.usersLocked === false
                          ? "Unlocked Users"
                          : "Locked Users"
                    },
                    on: {
                      change: function($event) {
                        return _vm.lockUnlockUsers()
                      }
                    },
                    model: {
                      value: _vm.usersLocked,
                      callback: function($$v) {
                        _vm.usersLocked = $$v
                      },
                      expression: "usersLocked"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-card-text",
                [
                  _c("v-skeleton-loader", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading(),
                        expression: "isLoading()"
                      }
                    ],
                    attrs: { type: "table" }
                  }),
                  _c("v-data-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isLoading(),
                        expression: "!isLoading()"
                      }
                    ],
                    attrs: {
                      id: "usersTable",
                      headers: _vm.computedHeaders,
                      items: _vm.users,
                      search: _vm.search,
                      "custom-sort": _vm.usersSort,
                      "sort-by": ["inscriptioRoleName"],
                      expanded: _vm.expandedUsers,
                      "item-key": "userId",
                      "sort-desc": ""
                    },
                    on: { "click:row": _vm.toggleExpandRow },
                    scopedSlots: _vm._u([
                      {
                        key: "expanded-item",
                        fn: function(ref) {
                          var headers = ref.headers
                          var item = ref.item
                          return [
                            _c(
                              "td",
                              {
                                staticClass: "px-0",
                                attrs: { colspan: _vm.computedHeaders.length }
                              },
                              [
                                _c("UserHistory", {
                                  attrs: { "user-id": item.userId }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.mmsCustomerRestrictedByOwner",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.mmsCustomerRestrictedByOwner !== null
                              ? _c("v-simple-checkbox", {
                                  attrs: { disabled: "", color: "primary" },
                                  model: {
                                    value: item.mmsCustomerRestrictedByOwner,
                                    callback: function($$v) {
                                      _vm.$set(
                                        item,
                                        "mmsCustomerRestrictedByOwner",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "item.mmsCustomerRestrictedByOwner"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.inscriptioRoleName",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.getRoleItem(item.roleName)["color"]
                              ? _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      color: _vm.getRoleItem(item.roleName)[
                                        "color"
                                      ],
                                      dark: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.inscriptioRole) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.lastSignInDays",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.lastSignInDays >=
                            item.configSignInExpirationDays
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-chip",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "error",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        item.lastSignInDays
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "User expired " +
                                          _vm._s(
                                            item.lastSignInDays -
                                              item.configSignInExpirationDays
                                          ) +
                                          " days ago"
                                      )
                                    ])
                                  ]
                                )
                              : _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.lastSignInDays) +
                                      "\n            "
                                  )
                                ])
                          ]
                        }
                      },
                      {
                        key: "item.lastScheduledMailingDays",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.tenantNowwId === 1
                                    ? ""
                                    : item.lastScheduledMailingDays ||
                                        "No Mailings"
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.actions",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm.showUserActions(item)
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "", color: "success" },
                                        on: {
                                          click: function($event) {
                                            return _vm.editUser(item)
                                          }
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                mdi-pencil\n              "
                                        )
                                      ]
                                    ),
                                    _vm.showUserDelete(item)
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              right: "",
                                              "x-offset": "",
                                              origin: "right",
                                              transition: "slide-x-transition"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              small: "",
                                                              color: "error"
                                                            },
                                                            nativeOn: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    mdi-delete\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "pa-5" },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Are you sure you want to delete this user?"
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "tertiary",
                                                          disabled: _vm.isLoading()
                                                        },
                                                        on: {
                                                          click: _vm.closeDelete
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Cancel\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "error",
                                                          loading: _vm.isLoading()
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteUser(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Yes\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var onTooltip = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                }
                                              },
                                              Object.assign({}, onTooltip)
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  mdi-chevron-" +
                                                  _vm._s(item._expandedIcon) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v("See details")])]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }