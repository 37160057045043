import { packages, roles } from './'

const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))))
// const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

const packageRolesCross = f(packages, roles)

export const packageRoles = packageRolesCross.map(x => (
  {
    text: `${x[0].value}-${x[1].value}`,
    value: `${x[0].value}-${x[1].value}`,
    package: x[0].value,
    role: x[1].value,
    color: x[1].color,
    packageOrder: x[0].order,
    packageEnum: x[0].enumValue,
    roleOrder: x[1].order,
    roleEnum: x[1].enumValue
  }
))
